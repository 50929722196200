import React from "react";
import { Intent, Position, Tag, Tooltip } from "@blueprintjs/core";
import { TableRow } from "../../../../store/table-model-factory";
import "styled-components/macro";
import { ICellRendererParams } from "@ag-grid-community/core/dist/es6/rendering/cellRenderers/iCellRenderer";
import _ from "lodash";

const NO_ACTION_REQUIRED = "No Action Required";
const ACTION_0 = "Schedule Customer Call/Workshop";
const ACTION_1 = "Schedule Training";
const ACTION_2 = "Schedule New Product Demo";
const DEFAULT_SUGGESTED_ACTION = NO_ACTION_REQUIRED;

export function SuggestedActionCellRenderer({
  data,
  value,
  valueFormatted,
}: ICellRendererParams) {
  const [toShow, tooltipTextToShow] = useSuggestedAction(data); //valueFormatted ?? value;
  const isNullAction = toShow === NO_ACTION_REQUIRED;

  let tagElem = (
    <Tag
      minimal
      intent={isNullAction ? Intent.NONE : Intent.PRIMARY}
      fill={true}
      css={`
        text-align: center;
        vertical-align: center;
      `}
    >
      {toShow}
    </Tag>
  );

  if (tooltipTextToShow) {
    tagElem = (
      <Tooltip content={tooltipTextToShow} position={Position.BOTTOM}>
        {tagElem}
      </Tooltip>
    );
  }

  return (
    <span
      css={`
        height: 26px;
        line-height: 26px;
      `}
    >
      {tagElem}
    </span>
  );
}

export function getSuggestedAction(data: TableRow): [string, string] {
  // if (predicate_0(data)) {
  //   return ACTION_0;
  // } else

  let text = DEFAULT_SUGGESTED_ACTION;
  let tooltipText = text;

  if (predicate_01(data)) {
    const mainContact = getMainContactName(data);
    text = `Contact ${mainContact}`;
    tooltipText = `Speak with ${mainContact} and remind them about mass trainings with the Customer Care team`;
  } else if (predicate_2(data)) {
    text = `Schedule Demo of ${data.zoho_upsell_potential_products[0]}`;
    tooltipText = text;
  }

  return [text, tooltipText];
}

function predicate_01(data: TableRow): boolean {
  return predicate_0(data) || predicate_1(data);
}

function getMainContactName(row: TableRow): string {
  const ret = row["1st Main Contact Name"];
  if (ret) {
    return _.startCase(_.toLower(ret));
  } else {
    return "the main contact";
  }
}

export function useSuggestedAction(data: TableRow): [string, string] {
  return getSuggestedAction(data);
}

function predicate_0(data: TableRow): boolean {
  return hadZeroActiveUsersInPastMonth(data);
}

function predicate_1(data: TableRow): boolean {
  if (hasDownsellRisk(data)) {
    if (hasUpsellRecommendations(data)) {
      if (hasHighChurnRisk(data)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function predicate_2(data: TableRow): boolean {
  if (hasUpsellRecommendations(data)) {
    if (hasDownsellRisk(data)) {
      if (hasHighChurnRisk(data)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function hadZeroActiveUsersInPastMonth(data: TableRow): boolean {
  return (data.active_user_base ?? 0) === 0;
}

export function hasDownsellRisk(data: TableRow): boolean {
  return (data.n_service_names_18mo_unused_in_past_month ?? 0) > 0;
}

export function hasUpsellRecommendations(data: TableRow): boolean {
  return (data.n_zoho_upsell_potential_products ?? 0) > 0;
}

export function hasHighChurnRisk(data: TableRow): boolean {
  return (data.churn_risk_score ?? 0) >= 30.0;
}
