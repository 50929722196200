import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../../components/AgTable";
import { AgColDefs } from "../../components/AgTable/types";
import { TableRow } from "../../store/table-model-factory";
import { CaseStatusOrigin } from "../../pages/case-status-comparison/case-status-comparison-page";
import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";

const CMSOR_DISPLAY_NAME_MAPPING = new Map(
  Object.entries({
    recoveryconnect: "Recovery Connect",
  })
);

const CMSOR_ABBREVIATED_NAME_MAPPING = new Map(
  Object.entries({
    recoveryconnect: "RC",
  })
);

const finalizeRowData = (rowData: TableRow[], origin: CaseStatusOrigin) => {
  const finalizedRowData = rowData
    .filter((row) => row.origin === origin)
    .map((row) => ({
      ...row,
      id: row.cms_case_id,
      status_match:
        !!row.status_origin__mapped &&
        !!row.status_destination &&
        row.status_origin__mapped === row.status_destination,
    }));
  return _.sortBy(finalizedRowData, "status_match");
};

export function CaseStatusComparisonTable({
  fullScreenEnabled = true,
  selectedOrigin,
  ...restProps
}: {
  fullScreenEnabled?: boolean;
  selectedOrigin: CaseStatusOrigin;
}) {
  const loading = _.max([
    useEnsureData("rdn_cases_statuses"),
    useEnsureData("rdn_cases_updates"),
  ]);

  let data = useStoreState((s) => s.rdn_cases_statuses.rowData);

  return loading ? (
    <Spinner />
  ) : (
    <CaseStatusComparisonTableInner
      fullScreenEnabled={fullScreenEnabled}
      selectedOrigin={selectedOrigin}
      rowData={finalizeRowData(data as TableRow[], selectedOrigin)}
      {...restProps}
    />
  );
}

export function CaseStatusComparisonTableInner({
  fullScreenEnabled = true,
  rowData,
  selectedOrigin,
  ...restProps
}: {
  fullScreenEnabled?: boolean;
  rowData: TableRow[];
  selectedOrigin: CaseStatusOrigin;
}) {
  const columnDefs = useColumnDefs(selectedOrigin);
  const initialCategoryFilters = {
    status_match: {
      value: [false],
    },
  };
  return (
    <AgTable
      categoryFilters={initialCategoryFilters}
      rowData={rowData}
      tableName="CaseStatusComparison"
      ribbonTitle={"Case Status Comparison"}
      columnDefs={columnDefs}
      {...restProps}
    />
  );
}

function getRecoveryConnectUrlForCase(rcCaseId: number) {
  return `https://recoveryconnect.mbsicorp.com/user/case/view/${encodeURIComponent(
    rcCaseId
  )}`;
}

function getRDNUrlForCase(rdnCaseId: number) {
  return `https://app.recoverydatabase.net/alpha_rdn/module/default/case2/?case_id=${encodeURIComponent(
    rdnCaseId
  )}`;
}

const EXTRACT_FRONT_VS_SKIP_FROM_CMSOR_CODENAME_PATT = /^.*(front|skip)$/;

function useColumnDefs(origin: CaseStatusOrigin): AgColDefs {
  return [
    {
      headerName: "Origin",
      field: "origin",
      type: "categoryColumn",
      headerTooltip: "Case manager system of record",
    },
    {
      headerName: "Origin\nSide",
      valueGetter: ({ data }) =>
        EXTRACT_FRONT_VS_SKIP_FROM_CMSOR_CODENAME_PATT.exec(
          data.cmsor_codename
        )[1],
      type: "categoryColumn",
      width: 85,
    },
    {
      headerName: "Origin Codename",
      field: "cmsor_codename",
      type: "categoryColumn",
      width: 220,
      hide: true,
    },
    {
      headerName: "Origin Case ID",
      field: "cmsor_case_id",
      filter: "agNumberColumnFilter",
      type: "linkedTextColumn",
      __linkValueGetter: ({ data }) =>
        data.origin === "recoveryconnect"
          ? getRecoveryConnectUrlForCase(data.cmsor_case_id)
          : null,
      __linkIsInternal: false,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "RDN Codename",
      field: "cms_codename",
      type: "categoryColumn",
      hide: true,
      width: 160,
    },
    {
      headerName: "RDN Case ID",
      field: "cms_case_id",
      filter: "agNumberColumnFilter",
      type: "linkedTextColumn",
      __linkValueGetter: ({ data }) => getRDNUrlForCase(data.cms_case_id),
      __linkIsInternal: false,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: `${CMSOR_ABBREVIATED_NAME_MAPPING.get(
        origin
      )} Status\nUpdated At`,
      field: "updated_at_origin",
      type: "timestampColumn",
      headerTooltip: `Latest update timestamp on ${CMSOR_DISPLAY_NAME_MAPPING.get(
        origin
      )} Status`,
      headerClass: ["ag-header-cell--preformatted"],
    },
    {
      headerName: "# Updates (past 30 days)",
      field: "num_updates",
      type: "integerColumn",
      headerTooltip:
        "Number of updates in the past 30 days. Click in any row to see the updates.",
      headerClass: ["ag-header-cell--preformatted"],
      width: 113,
    },
    {
      headerName: `${CMSOR_ABBREVIATED_NAME_MAPPING.get(origin)} Status`,
      field: "collateral_status_name_origin",
      type: "categoryColumn",
      headerTooltip: `${CMSOR_DISPLAY_NAME_MAPPING.get(origin)} Status`,
      width: 113,
    },
    {
      headerName: `${CMSOR_ABBREVIATED_NAME_MAPPING.get(
        origin
      )} Detailed Status`,
      field: "status_origin",
      type: "categoryColumn",
      headerTooltip: `${CMSOR_DISPLAY_NAME_MAPPING.get(
        origin
      )} Detailed Status`,
      width: 180,
    },
    {
      headerName: `${CMSOR_ABBREVIATED_NAME_MAPPING.get(
        origin
      )} Mapped\nStatus`,
      field: "status_origin__mapped",
      type: "categoryColumn",
      headerTooltip: `${CMSOR_DISPLAY_NAME_MAPPING.get(
        origin
      )} Status mapped to its RDN Status equivalent`,
      headerClass: ["ag-header-cell--preformatted"],
      width: 113,
    },
    {
      headerName: "RDN Status",
      field: "status_destination",
      type: "categoryColumn",
      headerTooltip: "RDN Status",
      width: 113,
    },
    {
      headerName: "Can Be Automated",
      field: "can_be_automated_rdn_status",
      type: "booleanColumn",
      headerTooltip: "The RDN Status can be automated.",
      width: 125,
    },
    {
      headerName: "Automated Status",
      valueGetter: (params) =>
        params.data.can_be_automated_rdn_status
          ? `The RDN status should be changed to "${params.data.automated_status_destination}"`
          : "",
      type: "textColumn",
      headerTooltip: "The RDN status in case of performing the automation.",
      width: 220,
    },
    {
      headerName: "Status\nMatch",
      field: "status_match",
      type: "booleanColumn",
      headerTooltip: "Check for Mapped Status equality against RDN Status",
      headerClass: ["ag-header-cell--preformatted", "compact-header-cell"],
    },
    {
      headerName: "VIN",
      field: "vin",
      type: "textColumn",
      headerTooltip: "Vehicle Identification Number",
    },
  ];
}
