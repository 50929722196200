import {
  Action,
  action,
  Computed,
  computed,
  thunk,
  Thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import { useStoreState } from "../hooks/ep";
import { Injections } from "./store-injections";
import { StoreModel } from "./model";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { toaster } from "../toaster";

type MailtoInfo = {
  entityType: "account";
  entityId: number | string;
  emailTemplateId: string;
};

export interface MiscModel {
  NAME: string;
  //
  resetData: Thunk<MiscModel, void, Injections, StoreModel>;
  onLogin: ThunkOn<MiscModel, Injections, StoreModel>;
  onResetMeData: ThunkOn<MiscModel, Injections, StoreModel>;
  //
  fullScreenModeEnabled: boolean;
  enableFullScreenMode: Action<MiscModel>;
  disableFullScreenMode: Action<MiscModel>;
  toggleFullScreenMode: Action<MiscModel>;
  //
  ra_payload: RA_Payload | null;
  ra_openModal: Action<MiscModel, RA_Payload>;
  ra_closeModal: Action<MiscModel>;
  //
  dataPipelineDataFreshness: string;
  setDataPipelineDataFreshness: Action<MiscModel, string>;
  //
  handleFetchMailtoLink: Thunk<MiscModel, MailtoInfo, Injections, StoreModel>;
  //
  mailtoInfoForAlert: MailtoInfo;
  setMailtoInfoForAlert: Action<MiscModel, MailtoInfo>;
  //
  mailtoLinkForAlert: string | null;
  setMailtoLinkForAlert: Action<MiscModel, string | null>;
  isMailtoAlertOpen: Computed<MiscModel, boolean, StoreModel>;
  //
  mailtoInfoForDrawer: MailtoInfo;
  setMailtoInfoForDrawer: Action<MiscModel, MailtoInfo>;
  //
  mailtoLinkForDrawer: string | null;
  setMailtoLinkForDrawer: Action<MiscModel, string | null>;
  isMailtoDrawerOpen: Computed<MiscModel, boolean, StoreModel>;
  //
  handleOpenMailtoLinkInsideApp: Thunk<MiscModel, void, Injections, StoreModel>;
  handleOpenMailtoLinkOutsideApp: Thunk<
    MiscModel,
    void,
    Injections,
    StoreModel
  >;
  handleCloseMailtoLinkOverlays: Thunk<MiscModel, void, Injections, StoreModel>;
}

export interface RA_Payload {
  actionName: string;
  snapshot: any;
}

const DEFAULT_MAILTO_INFO_VALUE = {
  entityType: null,
  entityId: null,
  emailTemplateId: null,
};
const DEFAULT_MAILTO_LINK_VALUE = null;

const miscModel: MiscModel = {
  NAME: "misc",
  fullScreenModeEnabled: false,
  enableFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = true;
  }),
  disableFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = false;
  }),
  toggleFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = !state.fullScreenModeEnabled;
  }),
  //
  ra_payload: null,
  ra_openModal: action((state, ra_payload) => {
    state.ra_payload = ra_payload;
  }),
  ra_closeModal: action((state) => {
    state.ra_payload = null;
  }),
  //
  resetData: thunk((actions) => {
    actions.disableFullScreenMode();
  }),
  onLogin: thunkOn(
    (__, storeActions) => storeActions.me.receiveInitialData,
    (actions, target, { getStoreState }) => {
      actions.resetData();
    }
  ),
  onResetMeData: thunkOn(
    (__, storeActions) => storeActions.me.resetData,
    (actions) => {
      actions.resetData();
    }
  ),
  //
  dataPipelineDataFreshness: "",
  setDataPipelineDataFreshness: action((state, value) => {
    state.dataPipelineDataFreshness = value;
  }),
  //
  handleFetchMailtoLink: thunk(
    async (actions, { entityType, entityId, emailTemplateId }) => {
      try {
        const resp = await makeAuthorizedGetRequestToBackend({
          url: makeUrl(
            `mailto_link/${emailTemplateId}/${entityType}/${entityId}`
          ),
          axiosConfig: {},
        });

        actions.setMailtoLinkForAlert(resp.data.mailto_link);
        actions.setMailtoInfoForAlert({
          entityType,
          entityId,
          emailTemplateId,
        });
        // window.open(resp.data.mailto_link);
      } catch (e) {
        toaster.error("Something went wrong. Please reload and try again");
      }
    }
  ),
  //
  mailtoInfoForAlert: DEFAULT_MAILTO_INFO_VALUE,
  setMailtoInfoForAlert: action((state, value) => {
    state.mailtoInfoForAlert = value;
  }),
  //
  mailtoLinkForAlert: DEFAULT_MAILTO_LINK_VALUE,
  setMailtoLinkForAlert: action((state, value) => {
    state.mailtoLinkForAlert = value;
  }),
  isMailtoAlertOpen: computed(
    [(s) => s.mailtoLinkForAlert, (s) => s.mailtoLinkForDrawer],
    (forAlert, forDrawer) => !!forAlert && !forDrawer
  ),
  //
  mailtoInfoForDrawer: DEFAULT_MAILTO_INFO_VALUE,
  setMailtoInfoForDrawer: action((state, value) => {
    state.mailtoInfoForDrawer = value;
  }),
  //
  mailtoLinkForDrawer: DEFAULT_MAILTO_LINK_VALUE,
  setMailtoLinkForDrawer: action((state, value) => {
    state.mailtoLinkForDrawer = value;
  }),
  isMailtoDrawerOpen: computed([(s) => s.mailtoLinkForDrawer], (v) => !!v),
  //
  handleOpenMailtoLinkInsideApp: thunk((actions, payload, { getState }) => {
    const s = getState();
    const mailtoLink = s.mailtoLinkForAlert;
    const mailtoInfo = s.mailtoInfoForAlert;
    actions.setMailtoLinkForDrawer(mailtoLink);
    actions.setMailtoInfoForDrawer(mailtoInfo);
  }),
  handleOpenMailtoLinkOutsideApp: thunk((actions, payload, { getState }) => {
    window.open(getState().mailtoLinkForAlert);
    actions.setMailtoLinkForAlert(DEFAULT_MAILTO_LINK_VALUE);
    actions.setMailtoInfoForAlert(DEFAULT_MAILTO_INFO_VALUE);
  }),
  handleCloseMailtoLinkOverlays: thunk((actions, payload, { getState }) => {
    actions.setMailtoLinkForAlert(DEFAULT_MAILTO_LINK_VALUE);
    actions.setMailtoInfoForAlert(DEFAULT_MAILTO_INFO_VALUE);
    //
    actions.setMailtoLinkForDrawer(DEFAULT_MAILTO_LINK_VALUE);
    actions.setMailtoInfoForDrawer(DEFAULT_MAILTO_INFO_VALUE);
  }),
};

export const useFullScreenModeEnabled = () =>
  useStoreState((s) => s.misc.fullScreenModeEnabled);

export default miscModel;
