import { computed, Computed } from "easy-peasy";
import _ from "lodash";
import { StoreModel } from "../model";
import {
  tableModelFactory,
  TableModel,
  TableRow,
} from "../table-model-factory";

export type RDNCasesUpdatesModel = TableModel & {
  rowDataById: Computed<
    RDNCasesUpdatesModel,
    { [key: number]: TableRow[] },
    StoreModel
  >;
};

export function getRDNCasesUpdatesModel(): RDNCasesUpdatesModel {
  return {
    ...tableModelFactory(
      "rdn_cases_updates",
      "cases/updates",
      (row) => row.update_id,
      []
    ),
    rowDataById: computed([(s) => s.initialData], (initialData: TableRow[]) =>
      _.groupBy(initialData, "update_id")
    ),
  };
}
