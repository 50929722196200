import { TableModel, tableModelFactory } from "./table-model-factory";

export interface LotDispositionUpdatesInformationModel extends TableModel {}

export function getLotDispositionUpdatesInformationModel(): LotDispositionUpdatesInformationModel {
  return {
    ...tableModelFactory(
      "lot_disposition_updates_information",
      "lot_disposition_updates_information",
      (row) => row.cms_case_codename
    ),
  };
}
