// eslint-disable-next-line
const { Table } = require("apache-arrow");

// @ts-ignore
export function getArrowTableSummary(tbl: Table) {
  return {
    schema: tbl.schema,
    "schema.fields[].name": tbl.schema.fields.map((f) => f.name),
    numRows: tbl.numRows,
    numCols: tbl.numCols,
    // byteLength: tbl.getByteLength(0),
    nullCount: tbl.nullCount,
  };
}
