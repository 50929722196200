// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCIikdAD92ZWJbZ5mkxRKPfbUeNLmkXTrc",
  authDomain: "flourine-cmax.firebaseapp.com",
  projectId: "flourine-cmax",
  storageBucket: "flourine-cmax.appspot.com",
  messagingSenderId: "913590005900",
  appId: "1:913590005900:web:696050463b47b5373acba9",
  measurementId: "G-86KD5JY69X",
};

export default firebaseConfig;
