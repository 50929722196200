import React from "react";
import { Redirect, Route } from "react-router";
import { useIsAuthorized } from "./helpers/useAuthorization";
import useRedirect from "./hooks/useRedirect";
import { UserRole } from "./store/model-me";
import { Icon as BP3Icon } from "@blueprintjs/core";
import AppWrapper from "./components/wrapper/AppWrapper";
import { SigninPage } from "./pages/signin-page";
// import { CustomersPage } from "./pages/customers-page";
// import { Customer360Page } from "./pages/customer360/customer360-page";
import { CmaxUsersPage } from "./cmax-users/pages/cmax-users-page";
import { CaseStatusPage } from "./pages/case-status-comparison/case-status-comparison-page";
import { LotDispositionUpdatesInformationPage } from "./pages/lot-disposition-updates-information/lot-disposition-updates-information-page";

export enum Routes {
  customers = "/customers",
  customer360 = "/customer360",
  //
  root = "/",
  dev = "/dev",
  signin = "/signin",
  cmax_users = "/cmax-users",
  lot_disposition_updates_information = "/lot-disposition-updates-information",
  //
  case_status_comparison = "/case-status-comparison",
  //
  DEFAULT = case_status_comparison,
  //
}

export const DEFAULT_ROUTES_BY_ROLE = {
  [UserRole.SAXECAP_DEV]: Routes.DEFAULT,
  [UserRole.ADMIN_READ_WRITE]: Routes.DEFAULT,
  [UserRole.ADMIN_READ_ONLY]: Routes.DEFAULT,
  [UserRole.CASE_MANAGER]: Routes.DEFAULT,
  [UserRole.DEFAULT]: Routes.DEFAULT,
};

export const SAXECAP_ROUTES_PATT = /^\/.*$/;

export const ADMIN_ROUTES_PATT =
  /^\/(signin|customers|customer360|cmax-users|lot-disposition-updates-information|case-status-comparison)$/;
export const DEFAULT_ROUTES_PATT =
  /^\/(signin|customers|customer360|lot-disposition-updates-information|case-status-comparison)$/;
export const CASE_MANAGER_ROUTES_PATT =
  /^\/(signin|lot-disposition-updates-information|case-status-comparison)$/;

export const ROLE_ALLOWED_ROUTES_PATTS = {
  [UserRole.SAXECAP_DEV]: SAXECAP_ROUTES_PATT,
  [UserRole.ADMIN_READ_WRITE]: ADMIN_ROUTES_PATT,
  [UserRole.ADMIN_READ_ONLY]: ADMIN_ROUTES_PATT,
  [UserRole.CASE_MANAGER]: CASE_MANAGER_ROUTES_PATT,
  [UserRole.DEFAULT]: DEFAULT_ROUTES_PATT,
};

export const USER_EXTRA_ALLOWED_ROUTES_PATTS = {};

export const leftNavMenuItems = [
  // {
  //   urlPath: Routes.customers,
  //   title: "Customers",
  //   icon: <BP3Icon icon={"home"} />,
  // },
  // {
  //   urlPath: Routes.customer360,
  //   title: "Customer 360",
  //   icon: <BP3Icon icon={"layout-circle"} />,
  // },
  {
    urlPath: Routes.case_status_comparison,
    title: "Case Status Comparison",
    icon: <BP3Icon icon={"comparison"} />,
  },
  {
    urlPath: Routes.lot_disposition_updates_information,
    title: "Lot Disposition Updates Information",
    icon: <BP3Icon icon={"panel-table"} />,
  },
  {
    // Keep Users at the bottom of the Left Nav Menu
    urlPath: Routes.cmax_users,
    title: "Customax Users",
    icon: <BP3Icon icon={"people"} />,
  },
];

export const BREADCRUMBS = [
  // { href: Routes.customers, icon: "home", text: "Customers" },
  // {
  //   href: Routes.customer360,
  //   icon: "layout-circle",
  //   text: "Customer 360",
  // },
  { href: Routes.cmax_users, icon: "people", text: "Customax Users" },
  {
    href: Routes.case_status_comparison,
    icon: "comparison",
    text: "Case Status Comparison",
  },
  {
    href: Routes.lot_disposition_updates_information,
    icon: "panel-table",
    text: "Lot Disposition Updates Information",
  },
];

function MyRoute({ route, Component, protect }) {
  let WrappedComponent;
  if (protect) {
    WrappedComponent = (props) => {
      const auth = useIsAuthorized(route);
      const doneLoading = !auth.loading;
      const isDefinitelyNotAuthorized = doneLoading && !auth.isAuthorized;
      const isDefinitelyAuthorized = doneLoading && auth.isAuthorized;
      useRedirect(() => isDefinitelyNotAuthorized, Routes.signin);
      return isDefinitelyAuthorized ? (
        <AppWrapper hideLeftNav={false}>
          <Component {...props} />
        </AppWrapper>
      ) : null;
    };
  } else {
    WrappedComponent = (props) => (
      <AppWrapper hideLeftNav={false}>
        <Component {...props} />
      </AppWrapper>
    );
  }
  return <Route exact path={route} component={WrappedComponent} key={route} />;
}

export const AllRoutes = [
  MyRoute({
    route: Routes.signin,
    Component: SigninPage,
    protect: false,
  }),
  // MyRoute({
  //   route: Routes.customers,
  //   Component: CustomersPage,
  //   protect: true,
  // }),
  // MyRoute({
  //   route: Routes.customer360,
  //   Component: Customer360Page,
  //   protect: true,
  // }),
  MyRoute({
    route: Routes.cmax_users,
    Component: CmaxUsersPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.lot_disposition_updates_information,
    Component: LotDispositionUpdatesInformationPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.case_status_comparison,
    Component: CaseStatusPage,
    protect: true,
  }),
  <Route render={() => <Redirect to={Routes.signin} />} key="default_route" />,
];
