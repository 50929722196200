import { assertType } from "../../helpers/assertions";
import { UserRole } from "../../store/model-me";
import { CmaxUsersTable } from "../../cmax-users/table/cmax-users-table";
import { CaseStatusUpdatesTable } from "../case-status/case-status-updates-table";
import { CaseStatusComparisonTable } from "../case-status/case-status-comparison-table";

interface EntityTableComponentType {
  (props: any): JSX.Element;
}

export type EntityName =
  | "cmax_user"
  | "rdn_cases_updates"
  | "rdn_cases_statuses";

export const ALL_ENTITY_NAMES: EntityName[] = [
  "cmax_user",
  "rdn_cases_statuses",
  "rdn_cases_updates",
];

export function isAuthorizedForEntity(
  entityName: EntityName,
  myRole: UserRole,
  isBackendRequest: boolean = false
): boolean {
  return true;
}

const ENTITY_TABLE_COMPONENTS: {
  [name in EntityName]: EntityTableComponentType;
} = {
  cmax_user: CmaxUsersTable,
  rdn_cases_statuses: CaseStatusComparisonTable,
  rdn_cases_updates: CaseStatusUpdatesTable,
};

export function getEntityTableComponent(entityName: EntityName) {
  assertType<EntityName>(entityName);
  return ENTITY_TABLE_COMPONENTS[entityName];
}
