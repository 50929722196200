import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { OnCellClicked } from "../AgTable/types";
import { CellClickedEvent } from "@ag-grid-community/core/dist/es6/events";
import "styled-components/macro";
import { useEnsureData } from "../../helpers/use-ensure-data";
import _ from "lodash";
import versionedLocalStorageKey from "../../helpers/versionedLocalStorageKey";
import { lighten } from "polished";
import { FinalizeRowData } from "../AgTable/ag_table";
import { CaseStatusComparisonTable } from "../../entities/case-status/case-status-comparison-table";
import { CaseStatusUpdatesTable } from "../../entities/case-status/case-status-updates-table";

export const DEFAULT_CASE_ID = -1;

export function CaseStatusSplitview(props) {
  const loading = [
    useEnsureData("rdn_cases_statuses"),
    useEnsureData("rdn_cases_updates"),
  ];
  return _.max(loading) ? <Spinner /> : <CaseStatusSplitviewInner {...props} />;
}

function get_selected_case_lsk(viewId) {
  return versionedLocalStorageKey(
    `${viewId}--case-status-splitview-page--selectedCaseId`
  );
}

function CaseStatusSplitviewInner({
  finalizeCaseRowData,
  whitelistedCaseTagIds = null,
  caseActionColDefs = null,
  whitelistedCasesTagIds = null,
  viewId,
}): JSX.Element {
  viewId = viewId || "";
  const selected_case_lsk = get_selected_case_lsk(viewId);
  const [selectedCaseId, setSelectedCaseId] = useState<number>(() => {
    const stored = localStorage.getItem(selected_case_lsk);
    return +(stored ?? DEFAULT_CASE_ID);
  });

  useEffect(() => {
    localStorage.setItem(selected_case_lsk, selectedCaseId.toString());
  }, [selectedCaseId, selected_case_lsk]);

  const onCellClicked_UpperSplit = (ev: CellClickedEvent) => {
    const caseRow = ev.data;
    const colDef = ev.colDef;
    const caseId = caseRow.cms_case_id;
    if (!colDef.cellRenderer) {
      setSelectedCaseId((v) => (caseId === v ? DEFAULT_CASE_ID : caseId));
    }
  };

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <UpperSplit
        onCellClicked={onCellClicked_UpperSplit}
        selectedCaseId={selectedCaseId}
        finalizeRowData={finalizeCaseRowData}
        whitelistedTagIds={whitelistedCaseTagIds}
        actionColDefs={caseActionColDefs}
        tableNameSuffix={viewId === "ss-dash" ? "ss-dash" : null}
      />
      {selectedCaseId === DEFAULT_CASE_ID ? null : (
        <LowerSplit
          selectedCaseId={selectedCaseId}
          whitelistedTagIds={whitelistedCasesTagIds}
          tableNameSuffix={viewId === "ss-dash" ? "ss-dash" : "cases"}
        />
      )}
    </div>
  );
}

const TABLE_HEIGHT = "calc((100vh - 64px - 40px - 40px) / 2 - 20px - 48px)";

function UpperSplit({
  onCellClicked,
  selectedCaseId,
  finalizeRowData,
  whitelistedTagIds,
  actionColDefs,
  tableNameSuffix = null,
}: {
  selectedCaseId: number;
  onCellClicked: OnCellClicked;
  finalizeRowData: FinalizeRowData;
  whitelistedTagIds: string[] | null;
  actionColDefs: any;
  tableNameSuffix?: string;
}): JSX.Element {
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & .ag-row[row-id="${selectedCaseId}"] {
          background-color: rgba(0, 21, 40, 0.7) !important;
          &.ag-row-hover {
            background-color: ${lighten(
              0.1,
              "rgba(0, 21, 40, 0.7)"
            )} !important;
          }
        }
      `}
    >
      <CaseStatusComparisonTable
        onCellClicked={onCellClicked}
        fullScreenEnabled={selectedCaseId === DEFAULT_CASE_ID}
        height={selectedCaseId === DEFAULT_CASE_ID ? null : TABLE_HEIGHT}
        finalizeRowData={finalizeRowData}
        whitelistedTagIds={whitelistedTagIds}
        actionColDefs={actionColDefs}
        tableNameSuffix={tableNameSuffix}
        selectedOrigin={"recoveryconnect"}
      />
    </div>
  );
}

function LowerSplit({
  selectedCaseId,
  whitelistedTagIds,
  tableNameSuffix = null,
}: {
  selectedCaseId: number;
  whitelistedTagIds: string[] | null;
  tableNameSuffix?: string;
}): JSX.Element {
  if (selectedCaseId !== DEFAULT_CASE_ID) {
    return (
      <CaseStatusUpdatesTable
        caseId={selectedCaseId}
        fullScreenEnabled={false}
        height={TABLE_HEIGHT}
        whitelistedTagIds={whitelistedTagIds}
        initialCategoryFilters={undefined}
        tableName={"Selected case updates"}
        tableNameSuffix={
          tableNameSuffix
            ? `${tableNameSuffix}__${selectedCaseId}`
            : selectedCaseId
        }
        selectedOrigin={"recoveryconnect"}
      />
    );
  } else {
    return null;
  }
}
