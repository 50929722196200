import { getStarsModel, StarsModel } from "./model-stars";
import { CmaxUserModel, getCmaxUserModel } from "./model-cmax-user";
import {
  getRDNCasesUpdatesModel,
  RDNCasesUpdatesModel,
} from "./cases/model-rdn-cases-updates";
import {
  getRDNCasesStatusesModel,
  RDNCasesStatusesModel,
} from "./cases/model-rdn-cases-status";
import {
  getLotDispositionUpdatesInformationModel,
  LotDispositionUpdatesInformationModel,
} from "./model-lot-disposition-updates-information";

export interface TableModels {
  cmax_user: CmaxUserModel;
  stars: StarsModel;
  rdn_cases_updates: RDNCasesUpdatesModel;
  rdn_cases_statuses: RDNCasesStatusesModel;
  lot_disposition_updates_information: LotDispositionUpdatesInformationModel;
  //
}

export type TableModelSliceName =
  | "cmax_user"
  | "stars"
  | "rdn_cases_updates"
  | "rdn_cases_statuses"
  | "lot_disposition_updates_information";
//

export const tableModels: TableModels = {
  cmax_user: getCmaxUserModel(),
  stars: getStarsModel(),
  rdn_cases_updates: getRDNCasesUpdatesModel(),
  rdn_cases_statuses: getRDNCasesStatusesModel(),
  lot_disposition_updates_information:
    getLotDispositionUpdatesInformationModel(),
};
