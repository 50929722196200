import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../../components/AgTable";
import { AgColDefs } from "../../components/AgTable/types";
import { CaseStatusOrigin } from "../../pages/case-status-comparison/case-status-comparison-page";
import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import { DEFAULT_CASE_ID } from "../../components/splitviews/case-status-splitview";

export function CaseStatusUpdatesTable({
  fullScreenEnabled = true,
  caseId = DEFAULT_CASE_ID,
  whitelistedTagIds = null,
  prefilterTagId = null,
  selectedOrigin,
  ...restProps
}: {
  fullScreenEnabled?: boolean;
  selectedOrigin: CaseStatusOrigin;
  caseId: number;
  whitelistedTagIds: string[];
  prefilterTagId?: number;
}) {
  const loading = _.max([
    useEnsureData("rdn_cases_statuses"),
    useEnsureData("rdn_cases_updates"),
  ]);

  return loading ? (
    <Spinner />
  ) : (
    <CaseStatusUpdatesTableInner
      fullScreenEnabled={fullScreenEnabled}
      caseId={caseId}
      prefilterTagId={prefilterTagId}
      whitelistedTagIds={whitelistedTagIds}
      {...restProps}
    />
  );
}

function useUpdates() {
  return useStoreState((s) => s.rdn_cases_updates.initialData);
}

function filterToUpdatesByCase(rowData, caseId) {
  // TODO: Need to be using cms_case_codename instead of cms_case_id / case_id because the
  //       same case_id could correspond to two cases: One in RDN_PRD_FRONT and one in RDN_PRD_SKIP
  return rowData.filter((row) => row.case_id === caseId);
}

export function CaseStatusUpdatesTableInner({
  caseId,
  prefilterTagId,
  whitelistedTagIds,
  tableName = null,
  tableNameSuffix = null,
  fullScreenEnabled = true,
  ...restProps
}: {
  fullScreenEnabled?: boolean;
  caseId: number;
  whitelistedTagIds: string[];
  prefilterTagId: number;
  tableName?: string;
  tableNameSuffix?: string;
}) {
  prefilterTagId = prefilterTagId || null;
  whitelistedTagIds = whitelistedTagIds || null;

  let rowData = useUpdates();

  let rowDataUpdates;

  if (caseId !== DEFAULT_CASE_ID) {
    rowDataUpdates = filterToUpdatesByCase(rowData, caseId);
  }

  const colDefs = useColumnDefs();

  return (
    <AgTable
      rowData={rowDataUpdates}
      tableName={tableName || "Status Updates"}
      tableNameSuffix={tableNameSuffix}
      ribbonTitle={`Updates for case ${caseId}`}
      columnDefs={colDefs}
      {...restProps}
    />
  );
}

function useColumnDefs(): AgColDefs {
  return [
    {
      headerName: "Update ID",
      field: "update_id",
      type: "integerIdentifierColumnColumn",
    },
    // {
    //   headerName: "RDN Case Codename",
    //   field: "cms_case_codename",
    //   type: "textColumn",
    // },
    // {
    //   headerName: "CMSOR Case Codename",
    //   field: "cmsor_case_codename",
    //   type: "textColumn",
    // },
    {
      headerName: "Company Name",
      field: "posted_by_company",
      type: "textColumn",
    },
    {
      headerName: "Name",
      field: "posted_by_name",
      type: "textColumn",
    },
    {
      headerName: "Update Date",
      field: "update_date",
      type: "dateColumn",
    },
    {
      headerName: "Hidden?",
      field: "is_hidden",
      type: "booleanColumn",
    },
    {
      headerName: "Update Type ID",
      field: "update_type",
      type: "integerIdentifierColumn",
      hide: true,
    },
    {
      headerName: "Update Type",
      field: "update_type_name",
      type: "categoryColumn",
    },
    {
      headerName:
        "Eligible to Become Client-Visible Update in RecoveryConnect?",
      field:
        "update_is_eligible_to_become_client_visible_update_in_recoveryconnect",
      type: "booleanColumn",
      width: 191,
    },
    {
      headerName: "Description",
      field: "update_text",
      type: "textColumn",
      valueFormatter: ({ value }): string => decodeHtml(value),
    },
  ];
}

function decodeHtml(value: string): string {
  const value_str = value
    .toString()
    .replace(/(&#(\d+);)/g, (match, capture, charCode) =>
      String.fromCharCode(charCode)
    );
  return value_str;
}
