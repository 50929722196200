import React from "react";
import { Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import AgTable from "../../components/AgTable";
import { AgColDefs } from "../../components/AgTable/types";
import { TableRow } from "../../store/table-model-factory";

export function LotDispositionUpdatesInformationTable({
  fullScreenEnabled = true,
  ...restProps
}) {
  const loading = useEnsureData("lot_disposition_updates_information");
  return loading ? (
    <Spinner />
  ) : (
    <LotDispositionUpdatesInformationTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function LotDispositionUpdatesInformationTableInner(props) {
  let rowData = useStoreState(
    (s) => s.lot_disposition_updates_information.initialData
  ) as TableRow[];

  let updatedRowData = rowData.map((row) => {
    return {
      ...row,
      cmsor_case_id: getCaseIdFromCaseCodename(row["cmsor_case_codename"]),
      cms_case_id: getCaseIdFromCaseCodename(row["cms_case_codename"]),
      origin: getOriginFromCmsorCaseCodename(row["cmsor_case_codename"]),
    };
  });

  const colDefs = useColDefs();

  return (
    <AgTable
      rowData={updatedRowData}
      columnDefs={colDefs}
      tableName="lot-disposition-updates-information"
      ribbonTitle={"Lot Disposition Updates Information"}
      {...props}
    />
  );
}

function getCaseIdFromCaseCodename(case_codename): string {
  let caseIdPatt = new RegExp(/\d+/g);
  return case_codename.match(caseIdPatt);
}

function getOriginFromCmsorCaseCodename(cmsor_case_codename): string {
  let originPatt = new RegExp(/(front|skip)/g);
  return cmsor_case_codename.match(originPatt);
}

function getRecoveryConnectUrl(args): string {
  return formatRecoveryConnectUrl(args.data.cmsor_case_id);
}

function formatRecoveryConnectUrl(rcCaseId: number) {
  return `https://recoveryconnect.mbsicorp.com/user/case/view/${encodeURIComponent(
    rcCaseId
  )}`;
}

function getRDNUrl(args): string {
  return formatRDNUrl(args.data.cms_case_id);
}

function formatRDNUrl(rdnCaseId: number) {
  return `https://app.recoverydatabase.net/alpha_rdn/module/default/case2/?case_id=${encodeURIComponent(
    rdnCaseId
  )}`;
}

function useColDefs(): AgColDefs {
  return [
    {
      headerName: "Origin\nSide",
      field: "origin",
      type: "categoryColumn",
      headerTooltip: "Recovery Connect Origin Side",
      width: 84,
      pinned: "left",
    },
    {
      headerName: "RC Case ID",
      field: "cmsor_case_id",
      type: "linkedTextColumn",
      __linkValueGetter: getRecoveryConnectUrl,
      __linkIsInternal: false,
      __linkIsNotAnEmail: true,
      headerTooltip: "Recovery Connect Case ID",
      pinned: "left",
      width: 120,
    },
    {
      headerName: "RDN Case ID",
      field: "cms_case_id",
      type: "linkedTextColumn",
      __linkValueGetter: getRDNUrl,
      __linkIsInternal: false,
      __linkIsNotAnEmail: true,
      headerTooltip: "RDN Case ID",
      pinned: "left",
      width: 130,
    },
    {
      headerName: "Has RDN Substatus Allowing Propagation?",
      field: "has_rdn_substatus_that_permits_lot_disposition_propagation",
      type: "booleanColumn",
      headerTooltip:
        "Whether the RDN case has the 'Pro Lot to RC' substatus. Customax will only propagate lot disposition " +
        "information for cases that have this substatus. After propagation, Customax removes this substatus from " +
        "the RDN case. As a result, you can think of the 'Pro Lot to RC' substatus as a one-time permission for " +
        "Customax to propagate the case's lot disposition. If the case's current action scenario does not support " +
        "propagation, then the one-time permission will be used whenever the case's action scenario does support " +
        "propagation",
      width: 200,
    },
    {
      headerName: "Scenario Not Yet Implemented?",
      field: "is_action_scenario_not_yet_implemented",
      type: "booleanColumn",
      headerTooltip:
        "Whether the lot disposition action scenario has not yet been implemented by the Customax team. " +
        "If checked/true, then has not yet been implemented, and therefore the RDN lot disposition will not be " +
        "propagated to RecoveryConnect (i.e. the scenario's action will not be taken).",
      width: 157,
    },
    {
      headerName: "RDN Lender Name",
      field: "rdn_lender_name",
      type: "categoryColumn",
      headerTooltip: "The name of the lender associated with the RDN case",
      width: 245,
    },
    {
      headerName: "RDN Auction Name",
      field: "rdn_auction_name",
      type: "textColumn",
      headerTooltip: "The name of the auction associated with the RDN case",
      width: 245,
    },
    {
      headerName: "RDN Transport/Redemption Date",
      field: "rdn_transport_redemption_date",
      type: "dateColumn",
      headerTooltip: "The transport/redemption date associated with the RDN case",
      width: 245,
    },
    {
      headerName: "RC Status",
      field: "rc_status",
      type: "categoryColumn",
      headerTooltip: "Recovery Connect Status",
      width: 120,
    },
    {
      headerName: "RDN Status",
      field: "rdn_status",
      type: "categoryColumn",
      headerTooltip: "RDN Status",
      width: 120,
    },
    {
      headerName: "Current RC Disposition",
      field: "rc_disposition",
      type: "categoryColumn",
      headerTooltip: "Recovery Connect Current Disposition",
      width: 120,
    },
    {
      headerName: "Current RDN Disposition",
      field: "rdn_disposition",
      type: "categoryColumn",
      headerTooltip: "RDN Current Disposition",
      width: 120,
    },
    {
      headerName: "Scenario Codename",
      field: "action_scenario_codename",
      type: "categoryColumn",
      headerTooltip: "Action Scenario Codename",
      width: 290,
    },
    {
      headerName: "Proposed RC Action",
      field:
        "proposed_recoveryconnect_action_to_implement_required_disposition",
      type: "categoryColumn",
      headerTooltip:
        "Proposed Action to Implement Required Disposition in Recovery Connect",
      width: 400,
    },
    {
      headerName: "Explanation of Proposed RC Action",
      field:
        "explanation_on_how_to_implement_required_disposition_in_recoveryconnect",
      type: "textColumn",
      width: 1860,
      headerTooltip:
        "Explanation on how to apply the disposition to Recovery Connect, if required",
      suppressSizeToFit: true,
      autoHeight: true,
      cellStyle: { minHeight: "48px" },
    },
  ];
}
