import { useCallback } from "react";
import _ from "lodash";
import { useSetSearchBoxText } from "./search-box-store";
import moment from "moment-timezone";
import { useMeData } from "../../hooks/useMe";

function formatDate(value) {
  const dateString = moment(value).format("YYYY-MM-DD");
  if (dateString.toLowerCase() === "invalid date") {
    return value;
  }
  return dateString;
}

const REMOVABLE_PREFIX__PATT = /^[-_]+/;
function getFileName(tableName: string, userTz) {
  if (tableName) {
    const tsString = moment().tz(userTz).format("YYYY-MM-DD");
    const entityName = tableName.replace(REMOVABLE_PREFIX__PATT, "");
    return `${tsString}-cmax-${entityName}.csv`;
  }
  return "export.csv";
}

export function useExportToCsv(gridRef) {
  const { timezone: userTz } = useMeData();

  return useCallback(() => {
    // noinspection JSUnresolvedVariable
    gridRef.current.gridOptions.api.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      shouldRowBeSkipped: () => false,
      processCellCallback: ({ column, value }) =>
        column.getColDef().type === "dateColumn" ? formatDate(value) : value,
      processHeaderCallback: null,
      fileName: getFileName(
        gridRef.current.gridOptions.context?.tableName,
        userTz
      ),
    });
  }, [gridRef]);
}

export function useAutoSizeAll(gridRef) {
  return useCallback(() => {
    // @ts-ignore
    const isCypressRuntime = !!window.Cypress;
    if (!gridRef.current || isCypressRuntime) {
      return;
    }
    const columnApi = gridRef.current.gridOptions.columnApi;
    const allColumnIds = columnApi
      .getAllColumns()
      .filter((column) => _.isUndefined(column.userProvidedColDef.width)) // If user supplied column width, then leave it be
      .map((column) => column.colId);
    // console.log("autoSizing");
    columnApi.autoSizeColumns(allColumnIds);
  }, [gridRef]);
}

export function useGetAllGridColumns(gridRef) {
  return useCallback(() => {
    if (gridRef.current === undefined) {
      return [];
    } else {
      return gridRef.current.columnApi.getAllGridColumns();
    }
  }, [gridRef]);
}

export function useGetIsColumnVisible(gridRef) {
  return useCallback(
    (colId) => {
      return gridRef.current
        ? gridRef.current.columnApi.getColumn(colId).visible
        : null;
    },
    [gridRef]
  );
}

export function useGetAreAllColumnsVisible(gridRef) {
  const getAllGridColumns = useGetAllGridColumns(gridRef);
  return useCallback(
    () => _.every(getAllGridColumns().map((col) => col.visible)),
    [getAllGridColumns]
  );
}

export function useSetQuickFilterText(gridRef) {
  const setText = useSetSearchBoxText();
  return useCallback(
    (text: string) => {
      setText(text);
      if (!gridRef.current) {
        return;
      }
      gridRef.current.api.setQuickFilter(text);
    },
    [setText, gridRef]
  );
}
