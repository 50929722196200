import { Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  makeAuthorizedDeleteRequestToBackend,
  makeAuthorizedPostRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { toaster } from "../toaster";
import {
  tableModelFactory,
  TableModel,
  TableRowId,
} from "./table-model-factory";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";

interface StarUnstarPayload {
  customerId: number;
  customerName: string;
}

export interface StarsModel extends TableModel {
  starCustomer: Thunk<StarsModel, StarUnstarPayload, Injections, StoreModel>;
  unstarCustomer: Thunk<StarsModel, StarUnstarPayload, Injections, StoreModel>;
  starredCustomerIds: Computed<StarsModel, Set<TableRowId>, StoreModel>;
}

export function getStarsModel(): StarsModel {
  return {
    ...tableModelFactory("stars", "stars", (row) => row.customer_id),
    starCustomer: thunk(
      async (actions, { customerId, customerName }, { getStoreState }) => {
        try {
          const resp = await makeAuthorizedPostRequestToBackend({
            url: makeUrl(`stars/${customerId}`),
            data: {},
            axiosConfig: undefined,
          });
          toaster.success(`Successfully starred ${customerName}`);
          actions.receiveInitialData(resp.data);
        } catch (e) {
          toaster.danger(`Failed to star ${customerName}`);
        }
      }
    ),
    unstarCustomer: thunk(
      async (actions, { customerId, customerName }, { getStoreState }) => {
        try {
          const resp = await makeAuthorizedDeleteRequestToBackend({
            url: makeUrl(`stars/${customerId}`),
            axiosConfig: undefined,
          });
          toaster.success(`Successfully un-starred ${customerName}`);
          actions.receiveInitialData(resp.data);
        } catch (e) {
          toaster.danger(`Failed to un-star ${customerName}`);
        }
      }
    ),
    starredCustomerIds: computed(
      [(s) => s.initialData, (s) => s.GET_ROW_ID],
      (initialData, GET_ROW_ID) =>
        initialData && new Set(initialData.map((row) => GET_ROW_ID(row)))
    ),
  };
}
