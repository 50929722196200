import { computed, Computed } from "easy-peasy";
import _ from "lodash";
import { timeit } from "../../helpers/timing";
import { StoreModel } from "../model";
import {
  tableModelFactory,
  TableModel,
  TableRow,
} from "../table-model-factory";

export type RDNCasesStatusesModel = TableModel & {
  rowData: Computed<RDNCasesStatusesModel, TableRow[], StoreModel>;
};

export function getRDNCasesStatusesModel(): RDNCasesStatusesModel {
  // TODO: Need to be using cms_case_codename instead of cms_case_id / case_id because the
  //       same case_id could correspond to two cases: One in RDN_PRD_FRONT and one in RDN_PRD_SKIP
  return {
    ...tableModelFactory(
      "rdn_cases_statuses",
      "cases/statuses",
      (row) => row.cms_case_id,
      [
        {
          name: "updated_cases",
          arity: "OneToMany",
          foreignName: "rdn_cases_updates",
          foreignField: "case_id",
          field: "cms_case_id",
        },
      ]
    ),
    rowData: computed(
      [
        (state) => state.initialData,
        (state) => state.relatedRowsMap,
        (state, storeState) => storeState.rdn_cases_updates.rowDataById,
      ],
      timeit((initialData, relatedRowbyCaseId, rowDataById) => {
        return initialData.map((row) => {
          const updates =
            relatedRowbyCaseId[row.cms_case_id]?.updated_cases ?? [];

          return {
            ...row,
            updates,
            num_updates: _.size(updates),
          };
        });
      }, "store.rowData")
    ),
  };
}
